import React, { useEffect, useState } from 'react';
import GatsbyImage from 'gatsby-image';
import styled from 'styled-components';
import Row, { rowShadowGreyCSS } from './Row';
import { sizeAsBase, sizeAsMax } from '../styles/utils';
import { rgba } from 'polished';
import { media } from '../styles';
import { h3, panelTitle } from '../styles/typography';
import useMedia, { mqMobile } from '../hooks/useMedia';
import { useAppear } from '../hooks/useAppear';

const StyledCover = styled.div`
    position: relative;
    overflow: hidden;
    width: 100%;
    background: ${props => props.theme.colors[props.backgroundColor]};
    color: ${props => props.theme.colors[props.backgroundColor === 'white' ? 'font' : 'white']};
`;

const StyledImage = styled(GatsbyImage)`
    position: relative;
    right: ${sizeAsBase(-3)};
    width: ${sizeAsBase(35)};

    ${media.large`
        right: ${sizeAsMax(-3)};
        width: ${sizeAsMax(35)};
    `}

    ${media.mobile`
        right: auto;
        width: calc(100% + ${sizeAsBase(6)});
        margin-left: ${sizeAsBase(-3)};
        margin-right: ${sizeAsBase(-3)};
    `}
`;

const StyledRow = styled(Row)`
    ${rowShadowGreyCSS}
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: initial;

    &:before,
    &:after {
        content: '';
        height: 100%;
        width: 1px;
        background: ${props => rgba(props.theme.colors.black, 0.14)};
        position: absolute;
        top: 0;
        left: 100%;
        z-index: 10;
    }

    &:before {
        display: none;
        left: auto;
        right: 100%;
    }

    ${media.mobile`
        flex-direction: column-reverse;

        &:before {
            display: block;
        }
    `}
`;

const StyledLeft = styled.div`
    width: ${sizeAsBase(25)};
    padding-left: ${sizeAsBase(2)};

    ${media.large`
        width: ${sizeAsMax(25)};
        padding-left: ${sizeAsMax(2)};
    `}

    ${media.mobile`
        width: auto;
        padding: ${props => props.theme.spacing.s20} ${sizeAsBase(2)} ${props =>
        props.theme.spacing.s80};
    `}
`;

const StyledText = styled.div`
    ${h3};
    margin-bottom: 30px;
`;

const StyledTitle = styled.h1`
    ${panelTitle}
    position: absolute;
    top: ${props => props.theme.spacing.s40};
    left: ${sizeAsBase(2)};

    ${media.large`
        left: ${sizeAsMax(2)};
    `}
`;

const CoverImageText = ({ children, title, text, images, color }) => {
    const imageKey = useMedia(mqMobile, ['mobile'], 'desktop');
    const [image, setImage] = useState(images[0].image.local.sharp);
    const refAppear = useAppear();

    useEffect(() => {
        if (images.length > 1) {
            const idx = Math.floor(Math.random() * images.length);
            setImage(images[idx].image.local.sharp);
        }
    }, []);

    return (
        <StyledCover backgroundColor={color}>
            <StyledRow>
                <StyledLeft>
                    <div ref={refAppear}>
                        {title && <StyledTitle>{title}</StyledTitle>}
                        <StyledText dangerouslySetInnerHTML={{ __html: text }} />
                        {children}
                    </div>
                </StyledLeft>
                <StyledImage fluid={image[imageKey]} />
            </StyledRow>
        </StyledCover>
    );
};

CoverImageText.defaultProps = {
    color: 'font'
};

export default CoverImageText;
