import { useEffect, useRef } from 'react';
import { theme } from '../styles';
import EmitterManager from '../managers/EmitterManager';

function handleIntersection(entries) {
    entries.forEach(entry => {
        if (entry.isIntersecting && entry.intersectionRatio > 0.0) {
            const handleTransitionEnd = event => {
                const element = event.currentTarget;
                element.removeEventListener('transitionrend', handleTransitionEnd);
                element.style.removeProperty('opacity');
                element.style.removeProperty('transition');
                this.unobserve(element);
            };

            entry.target.addEventListener('transitionend', handleTransitionEnd, false);
            entry.target.style.opacity = 1;
        }
    });
}

const observer =
    typeof IntersectionObserver !== 'undefined'
        ? new IntersectionObserver(handleIntersection, {
              root: null,
              rootMargin: '0px 0px -30% 0px',
              threshold: [0.0, 1.0]
          })
        : null;

export const useAppear = (delay = 0, attachOnReady = true) => {
    const target = useRef();

    const observe = () => observer && observer.observe(target.current);
    const unobserve = () => observer && observer.unobserve(target.current);

    useEffect(() => {
        target.current.style.opacity = 0;

        setTimeout(() => {
            target.current.style.transition = `opacity 1.5s ${theme.easings.easeOutExpo} ${delay}s`;
        }, 0);

        if (attachOnReady) {
            EmitterManager.on('page:visible', observe);
        } else {
            observe();
        }

        return () => {
            if (attachOnReady) {
                EmitterManager.removeListener('page:visible', observe);
            } else {
                unobserve();
            }
        };
    }, []);

    return target;
};
